<template>
  <tr class="pointer" v-if="car" :id="rowId">
    <td @click="showCategories">{{ car.id }}</td>
    <td @click="showCategories">{{ car.content }}</td>
    <td @click="showCategories">{{ formattedDate }}</td>
    <td>
      <modal-btn btn-class="btn-sm btn-primary mr-1" :target="`#${editId}`">تعديل</modal-btn>
      <modal-btn btn-class="btn-sm btn-soft-danger" :target="`#${deleteModalId}`">حذف</modal-btn>
    </td>
    <teleport to="#app">
      <confirm-model
        :target-id="editId"
        title="تعديل مركبة"
        confirm-text="تعديل"
        @confirmed="clickById(editSubmitId)">
        <form @submit.prevent="update">
          <div class="form-group">
            <label>إسم المركبة</label>
            <FormInput
              :id="`content${car.id}`"
              name="content"
              :value="car.content"
              placeholder="إسم المركبة"
            />
          </div>
          <button hidden :id="editSubmitId"></button>
        </form>
      </confirm-model>
      <items-destroyer
        :target-id="deleteModalId"
        :url="deleteUrl"
        :row-id="rowId"
        @deleted="deleted">
        <span>هل أنت متأكد من حذف</span><span class="link">&nbsp;{{ car.content }}&nbsp;</span> ؟
      </items-destroyer>
    </teleport>
  </tr>
</template>

<script>
import date from "@/common/dateMethods"
import ModalBtn from "@/components/UI/ModalBtn"
import ConfirmModel from "@/components/UI/ConfirmModel"
import FormInput from "@/components/Forms/FormInput"
import ItemsDestroyer from "@/components/Common/ItemsDestroyer"
import Storage from "@/common/Storage";

export default {
  components: {ItemsDestroyer, FormInput, ConfirmModel, ModalBtn},
  props: {
    car: {required: true}
  },
  computed: {
    rowId() {
      return this.car ? `row-car-${this.car.id}` : ''
    },
    formattedDate() {
      return this.car ? date.withoutTime(this.car.created_at) : ''
    },
    editId() {
      return this.car ? `edit-car-${this.car.id}` : ''
    },
    deleteModalId() {
      return this.car ? `delete-car-${this.car.id}` : ''
    },
    editSubmitId() {
      return this.car ? `edit-car-btn-${this.car.id}` : ''
    },
    deleteUrl() {
      return this.car ? `cars/${this.car.id}` : ''
    }
  },
  methods: {
    async update(e) {
      if (!this.car)
        return http.errorMessage('لم يتم تحديد المركبة')

      http.inputsId = this.car.id;
      const formData = new FormData(e.target)
      formData.set('_method', 'PUT')

      const response = await http.send(`cars/${this.car.id}`, formData)
      if (http.responseAccepted(response, 'car')) {
        this.$emit('updated', response.data.car)
        http.successMessage(messages.saved)
        this.hideModal(`#${this.editId}`)
      }
      http.inputsId = '';
    },
    deleted(id) {
      this.$emit('deleted', id)
      Storage.remove('manufacturers')
    },
    showCategories() {
      this.$router.push({name: 'cars.categories', params: {car: this.car.id}})
    }
  }
}
</script>
