<template>
  <div class="container">
    <div class="row">
      <div class="col-12 pm-0 d-flex justify-content-between flex-wrap">
        <div class="d-flex">
          <FormInput type="search" id="search" placeholder="اسم السيارة" input-class="max-w-200px" @changed="searchChanged" />
          <button class="btn btn-info btn-sm rounded-0" @click="filter">
            <tooltip title="بحث">
              <i class="fa fa-search"></i>
            </tooltip>
          </button>
        </div>

        <modal-btn :target="`#${storeId}`" btn-class="btn-info btn-sm">
          <span>إضافة مركبة</span>&nbsp;
          <i class="fa fa-plus"></i>
        </modal-btn>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 pm-0">
        <div class="table-responsive">
          <table class="table table-bordered table-striped table-hover" :class="{'shadow-sm': !emptyList}">
            <thead>
            <tr class="bg-bb">
              <th>رقم المركبة</th>
              <th>الاسم</th>
              <th>تاريخ الإنشاء</th>
              <th></th>
            </tr>
            </thead>
            <tbody class="bg-light">
            <tr v-if="emptyList">
              <td colspan="4" class="font-weight-bolder">لا يوجد</td>
            </tr>
            <ManufacturerItem
              v-else
              v-for="item in list"
              :key="item.id"
              :car="item"
              @updated="updated"
              @deleted="deleted"
            />
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 pm-0">
        <ThePaginator
          :total="total"
          :url="defaultUrl"
          :last-page="lastPage"
          :links="links"
          @link-changed="setLink"
        />
      </div>
    </div>

    <teleport to="#app">
      <confirm-model
        :target-id="storeId"
        title="إضافة مركبة"
        confirm-text="تعديل"
        @confirmed="clickById(storeSubmitId)">
        <form @submit.prevent="store">
          <div class="form-group">
            <label>إسم المركبة</label>
            <FormInput id="content" placeholder="إسم المركبة" />
          </div>
          <button hidden :id="storeSubmitId"></button>
        </form>
      </confirm-model>
    </teleport>
  </div>
</template>

<script>
import pagination from "@/mixins/pagination"
import ThePaginator from "@/components/UI/ThePaginator"
import ManufacturerItem from "@/components/Admin/ManufacturerItem"
import ModalBtn from "@/components/UI/ModalBtn"
import ConfirmModel from "@/components/UI/ConfirmModel"
import FormInput from "@/components/Forms/FormInput"
import Tooltip from "@/components/UI/Tooltip";
import Storage from "@/common/Storage";

export default {
  components: {Tooltip, FormInput, ConfirmModel, ModalBtn, ManufacturerItem, ThePaginator},
  mixins: [pagination],
  data() {
    return {
      search: ''
    }
  },
  computed: {
    defaultUrl() {
      return '/cars'
    },
    storeSubmitId() {
      return 'store-car-btn'
    },
    storeId() {
      return 'store-car'
    }
  },
  methods: {
    async store(e) {
      const formData = new FormData(e.target)

      if (!formData.get('content'))
        return http.errorMessage(messages.checkFields)

      const response = await http.send('cars', formData)
      if (http.responseAccepted(response)) {
        this.updated(response.data.car)
        http.successMessage(messages.saved)
        this.hideModal(`#${this.storeId}`)

        Storage.remove('manufacturers')
        $_('#content').val('')
      }
    },
    searchChanged(event) {
      this.search = !!event ? event.target.value : ''
    },

    async filter() {
      const response = await http.send('cars', {_method: 'GET', search: this.search})

      if (http.responseAccepted(response)) {
        this.setPagination(response.data)
      }
    }
  },
  async created() {
    await this.setList();
  }
}
</script>